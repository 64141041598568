@import "../../../../styles/theme.scss";

.NavColumnContainer {
  display: flex;
  justify-content: space-between;

  &__bellIcon {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  &__bellIconNotificationAlert {
    display: flex;
    margin-right: 20px;
  }

  &__notificationAlert {
    width: 5px;
    height: 5px;
    flex-grow: 0;
    border-radius: 100px;
    background-color: #85bb65;
  }

  &__wrapper {
    position: relative;
    display: inherit;
  }

  &__accountDetailWrapper {
    flex-shrink: 0;
  }

  &__dropdownArrow {
    margin-left: 5px;
  }

  &__dropdownText {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $navy;
    font-size: 15px;
    line-height: 16.5px;
    font-weight: 500;

    &_Active {
      .dropdownArrow {
        transform: rotate(180deg);
      }
    }
  }

  &__link {
    margin-right: 32px;
  }

  &__link {
    color: $secondary;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    transition: color 0.3s;
    height: 24px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: $main;
    }
  }

  &__linkWrapper {
    display: flex;
    align-items: center;
    width: 60%;
    flex-shrink: 0;
  }

  &__logo {
    height: 26px;
  }

  &__logoLink {
    height: 24px;
    margin-right: 32px;
  }

  &__searchIcon {
    margin-right: 20px;
    cursor: pointer;
  }

  &__searchNavWrapper {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  &__signInButton {
    font-size: 14px;
  }

  &__signInLink {
    margin-right: 24px;
    font-size: 14px;
  }

  &__linkItem {
    position: relative;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border-radius: 8px;
    padding: 8px 0;
    z-index: 1000;
    min-width: 200px;
    font-size: 14px;
    box-shadow: 0 4px 32px 0 rgba(9, 28, 55, 0.1);
  }
  
  &__dropdownItem {
    display: block;
    padding: 8px 12px;
    color: black;
    text-decoration: none;
  }

  &__dropdownItemImg {
    padding-right: 8px;
    width: 28px;
  }
  
  &__dropdownItem:hover {
    background-color: $pale-mint;
  }

  &__appPillLink{
    font-size: 14px;
    border-radius: 1rem;
    padding: 6px 10px;
    border: $tiicker-green  1px solid;
    color: $secondary;
    line-height: 1.2;

    &:hover {
      color: $main;
    }
  }

  &__dropdownItem_clickable {
    cursor: pointer;
  }
}

.hidden {
  visibility: hidden;
}
